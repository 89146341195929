import { useEffect, useState } from "react";
import HelpPanel from "./HelpPanel";
import Loading from "../Common/Loading";
import IntegrationForm from "./IntegrationForm";
import { confirmAlert } from "react-confirm-alert";
import { Link, useParams } from "react-router-dom";
import IntegrationPreviewForm from "./IntegrationPreviewForm";
import { deleteIntegration, getAppDetails, insertIntegration } from "../../api/integrationApi";
import Utils from "../../helpers/Utils";
import './setupForm.css';

const IntegrationApp = () => {

    const { appId } = useParams();
    const [data,setData] = useState(null);
    const [forms,setForms] = useState([]);
    const [app,setApp] = useState(null);
    const [edit,setEdit] = useState(false);

    useEffect(() => {

        document.title = `Integration | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('integration');

        getAppDetails({appId : appId}).then(res => {
            setData(res.data.data.userIntegrations);
            setForms(res.data.data.integrationForms);
            setApp(res.data.data.app);
        })
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveApi = (params, callback) => {
        params['app_id'] = appId;
        insertIntegration({formParams : params}).then(res => {
            callback();
            if(res.data.status === 'success') {
                Utils.showNotification(res.data.message,"SUCCESS");
                setData(res.data.data);
                setEdit(false);
            } else if (res.data.status === 'validation-error') {
                Utils.showNotification( res.data.message[Object.keys(res.data.message)[0]][0],"ERROR");
            } else {
                Utils.showNotification(res.data.message,"ERROR");
            }
        });
    }

    const deleteApi = () => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to want to delete this api?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteIntegration({id : data.id})
                        .then(res => {
                            if(res.data.success) {
                                Utils.showNotification(res.data.message,"SUCCESS");
                                setData(null);
                                setEdit(false);
                            } else {
                                Utils.showNotification(res.data.message,"ERROR");
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const showIntegrationForm = () => (
        forms.map((form,index) => {
            return <IntegrationForm
                key={index} 
                form={form}
                saveApi={saveApi}
                data={data !== null && parseInt(data.form_id) === parseInt(form.id) ? data : null}
            />
        })
    )

    return ( 
        <div className="app_integration_form_section">
            <div className="page_heading">
                <h4>Integration</h4>
            </div>
            <div className="page_title">
                {
                    app === null ? <Loading/> : <h5>{app.name} setup</h5>
                }
                
                <Link to={'/integrations'} className="accent--bg--color">
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5815 0.706524C6.82489 0.706048 7.06076 0.790813 7.24817 0.946107C7.35365 1.03355 7.44083 1.14095 7.50474 1.26215C7.56865 1.38334 7.60802 1.51595 7.6206 1.65239C7.63318 1.78882 7.61872 1.9264 7.57805 2.05723C7.53738 2.18807 7.4713 2.3096 7.38358 2.41486L2.71692 7.99819L7.21692 13.5919C7.30344 13.6985 7.36806 13.8211 7.40705 13.9527C7.44604 14.0843 7.45864 14.2223 7.44412 14.3588C7.4296 14.4953 7.38825 14.6276 7.32244 14.748C7.25664 14.8685 7.16767 14.9747 7.06067 15.0607C6.95289 15.1555 6.82667 15.227 6.68994 15.2708C6.55321 15.3145 6.40891 15.3295 6.26611 15.3149C6.1233 15.3002 5.98507 15.2562 5.86009 15.1855C5.73511 15.1149 5.62608 15.0192 5.53983 14.9044L0.508583 8.65444C0.355373 8.46805 0.271617 8.23426 0.271617 7.99298C0.271617 7.75171 0.355373 7.51791 0.508583 7.33152L5.71692 1.08152C5.82141 0.955464 5.95416 0.855815 6.10438 0.79066C6.2546 0.725505 6.41806 0.696679 6.5815 0.706524Z" fill="white"/>
                    </svg>	
                    Back
                </Link>
            </div>
            
            {
                app === null ? <Loading/> :
                <>
                    {
                        data === null || edit ?
                        showIntegrationForm() : 
                        <IntegrationPreviewForm 
                            form={forms.find(x => parseInt(x.id) === parseInt(data.form_id))}
                            data={data}
                            deleteApi={deleteApi}
                            showEdit={() => setEdit(true)}
                        />
                    }
                    <HelpPanel name={app.name}/>
                </>
            }
        </div>
    );
}
 
export default IntegrationApp;