import { makeStyles } from "@material-ui/core";

export const useFacebookSetupRequiredStyle = makeStyles({
    root: {
      minWidth: 275,
      maxWidth : "80%",
      margin : "auto"
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 17,
    },
    subtitle: {
      marginBottom: 12,
    },
    body : {
        padding : 20
    }
});

export const useFacebookConnectStyle = makeStyles({
    root: {
      minWidth: 275,
      maxWidth : "80%",
      margin : "auto"
    },
    title: {
      fontSize: 17,
    },
    subtitle: {
      marginBottom: 12,
    },
    body : {
        display:"flex",
        justifyContent : "center",
        alignItems : "center"
    },
    link : {
        padding : 20,
        width : 500,
        background : "#3C7EF3",
        color : "#fff",
        borderRadius : 10,
        textAlign : "center",
        margin : "20px 0",
        display : "inline-flex",
        justifyContent : "center"
    }
});