import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { useEffect, useMemo, useState } from "react";
import { getMapFields, storeMapFields } from "../../api/facebookApi";
import { DropdownMenuProps, FACEBOOK_FIELD_TYPE } from "../../constants/CoreConstants";
import Utils from "../../helpers/Utils";
import GlobalModal from "../Common/Modal/GlobalModal";
import MapFieldsLoader from "./MapFieldsLoader";

const FacebookMapFieldModal = (props) => {

    const [open,setOpen] = useState(props.open);
    const [fields,setFields] = useState([]);
    const [systemFields,setSystemFields] = useState(null);
    const [customFields,setCustomFields] = useState([]);
    const [savedFields,setSavedFields] = useState({});

    useEffect(() => {
        getMapFields({formId : props.formId}).then(res => {
            if (res.data.success) {
                setFields(res.data.data.fields);
                setSystemFields(res.data.data.systemFields);
                setCustomFields(res.data.data.customFields);
                let saveFieldsObj = {};
                res.data.data.savedFields.forEach(saveField => {
                    if(saveField.type === FACEBOOK_FIELD_TYPE.SYSTEM) {
                        saveFieldsObj[saveField.fb_field_key] = saveField.system_field_key;
                    } else {
                        saveFieldsObj[saveField.fb_field_key] = 'custom_'+saveField.system_field_key;
                    }
                });
                res.data.data.fields.forEach(field => {
                    if(saveFieldsObj[field.key] === undefined) {
                        saveFieldsObj[field.key] = null;
                    }
                });
                setSavedFields(saveFieldsObj);
            } else {
                Utils.showNotification('Unable to get fields','ERROR');
                closeModal();
            }
            
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            props.onClose();
        }, 500);
    }

    const renderSystemFields = 
        useMemo(() => {
            let sysArr = [];
            for(const key in systemFields) {
                sysArr.push(<MenuItem className="dropdownhelper-menuitem-class" key={key} value={key}>{systemFields[key]}</MenuItem>);
            }
            customFields.forEach(customField => {
                sysArr.push(<MenuItem className="dropdownhelper-menuitem-class" key={customField.id} value={`custom_${customField.id}`}>{customField.title}</MenuItem>);
            });
            return sysArr;
        },[systemFields,customFields]);

    const onChangeField = (fbKey,fieldKey) => {
        let saveFieldObj  = {...savedFields};
        saveFieldObj[fbKey] = fieldKey;
        setSavedFields(saveFieldObj);
    }

    const saveFields = (e) => {
        e.preventDefault();
        let saveFieldObj  = { form_id : props.formId,page_id : props.pageId };
        let systemFieldsObj = {};
        let customFieldsObj = {};
        let hasEmailOrPhoneField = false;
        for(const key in savedFields) {
            if(savedFields[key] === 'email' || savedFields[key] === 'number') {
                hasEmailOrPhoneField = true;
            }
            if(savedFields[key] !== null && savedFields[key].includes("custom_")) {
                customFieldsObj[key] = savedFields[key].replace('custom_','');
            } else {
                systemFieldsObj[key] = savedFields[key];
            }
        }
        if(!hasEmailOrPhoneField) {
            window.showNotification('ERROR','Either email or phone field must be present.');
            return;
        }

        saveFieldObj.systemFields = systemFieldsObj;
        saveFieldObj.customFields = customFieldsObj;

        storeMapFields(saveFieldObj).then(res => {
            Utils.showNotification(res.data.message,res.data.success ? 'SUCCESS' : 'ERROR');
            closeModal();
        });
    }
    

    return ( 
        <GlobalModal
            open={open}
            onClose={closeModal}
            title={'Map Fields'}
            onSubmit={saveFields}
            buttonText={'Save'}
            buttonIcon={<Save/>}>
            {
                fields.length === 0 ? <MapFieldsLoader/> :
                fields.map((field,index) => {
                    return <div key={index}>
                        <h3 className="map_field__modal_title mt10pxSpace"><code>[FB Field]</code> {field.label}</h3>
                        <FormControl className="alt customFormControlFb">
                            <Select
                                MenuProps={DropdownMenuProps} 
                                className={'map_field__modal_select'}
                                name={field.key} 
                                value={savedFields[field.key] === undefined ? null : savedFields[field.key]}
                                onChange={(e) => onChangeField(field.key,e.target.value)}
                                displayEmpty
                                inputProps={ { 'aria-label': 'Without label' } }>
                                <MenuItem disabled value={null}>Select System Field</MenuItem>
                                { renderSystemFields }
                            </Select>
                        </FormControl>
                        
                    </div>
                })
            }
        </GlobalModal>
    );
}
 
export default FacebookMapFieldModal;