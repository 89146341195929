import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getAllIntegrations = async (payload) => {
    return sendRequest("POST", Api.getAllIntegrations, payload);
}

export const getAppDetails = async (payload) => {
    return sendRequest("POST", Api.getAppDetails, payload);
}

export const insertIntegration = async (payload) => {
    return sendRequest("POST", Api.insertIntegration, payload);
}

export const deleteIntegration = async (payload) => {
    return sendRequest("POST", Api.deleteIntegration, payload);
}