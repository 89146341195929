import { LEADFLOW_STATUS } from "../constants/CoreConstants";
import * as ACTION from "../constants/facebookActionTypes";

const initialState = {
    facebookApp: null,
    facebookUser: null,
    loginUrl : '',
    isLoading : true,
    fbImage : '',
    fbName : '',
    pageData : [],
    subscribedPages : [],
    subscribeLoading : false,
    allFbForms : [],
    allCampaigns : null,
    allLeadFlow : null,
};

const facebookReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_FACEBOOK_LOADING:
            return {
                ...state,isLoading : action.payload.isLoading
            }
        case ACTION.STORE_FACEBOOK:
            return {...state,
                isLoading : false, 
                facebookApp: action.payload.facebookApp,
                facebookUser : action.payload.facebookUser,
                loginUrl : action.payload.loginUrl,
                fbImage : action.payload.fbImage,
                fbName : action.payload.fbName,
                pageData : action.payload.pageData,
                subscribedPages : action.payload.subscribedPages,
                allFbForms : appendPagesAndForms(action.payload.subscribedPages,action.payload.allFbForm,action.payload.userPages),
                allCampaigns : action.payload.allCampaigns,
                allLeadFlow : action.payload.allLeadFlow,
            }
        case ACTION.FETCH_FACEBOOK_SUBSCRIBES_LOADING:
            return {
                ...state,
                subscribeLoading : action.payload.subscribeLoading            
            }
        case ACTION.STORE_SUBSCRIBED_PAGES:
            return {...state,
                subscribeLoading : false, 
                subscribedPages : action.payload.subscribedPages,
                allFbForms : appendPagesAndForms(action.payload.subscribedPages,action.payload.allFbForm,action.payload.userPages)
            }
        case ACTION.RESET_FACEBOOK_USER_DATA:
            return {...state,facebookUser : null};
        default:
            return state;
    }
}

const appendPagesAndForms = (pages,forms,userPages) => {
    let tempFormArr = [];

    pages.forEach(page => {
        let hasForm = false;
        forms.forEach(form => {
            if(page.id === form.page_id) {
                hasForm = true;
                let userPage = userPages.find(o => o.page_id === page.id);
                let fbPageLeadflowId = null;
                let leadFlowId = null;
                let campaignId = null;
                let totalContact = 0;
                let leadFlowStatus = LEADFLOW_STATUS.STATUS_PAUSE;
                let userPageId = null;

                if(userPage !== undefined) {
                    userPage.page_lead_flow.forEach(leadFlow => {
                        if(leadFlow.form_id === form.id) {
                            fbPageLeadflowId = leadFlow.id;
                            campaignId = leadFlow.campaign_id;
                            leadFlowId = leadFlow.leadflow_id;
                            leadFlowStatus = leadFlow.status;
                            totalContact = leadFlow.total_contact;
                        }
                    });
                    userPageId = userPage.id;
                } 

                tempFormArr.push({...page,form : form,page_lead_flow : {
                    id : fbPageLeadflowId,
                    leadflow_id : leadFlowId,
                    campaign_id : campaignId,
                    total_contact : totalContact,
                    status : leadFlowStatus,
                    user_page_id : userPageId
                }});
            }
        });
        if(!hasForm) {
            tempFormArr.push({...page,form : null});
        }
    });
    return tempFormArr
}

export default facebookReducer;
