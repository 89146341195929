import React from 'react'
import "./errorPage.css"
const PaymentFailMessage = (props) => {

  return (
    // <div>
    //   <h6>{allParams.message}</h6>

    // </div>
    <div className="notfound-page-root">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
        </div>
        <h2>{props.errorMessage ?? 'Something went wrong'}</h2>
      </div>
    </div>

  )
}

export default PaymentFailMessage