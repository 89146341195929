import {getCookie, getCookieLogin} from "./Cookie";

/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 ? email.substr(0, 13)+' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 ? name.substr(0, 13)+' ...' : name;
}

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Success', type = 'SUCCESS') => {
    window.showNotification(type, message);
};

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name) => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = 'X'
            if(name === 'null null' || name === '' || name === ' '){
                fullNameInitial = 'X';
            }else if(name !== 'undefined' || name !== null){
                fullNameInitial = name.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return 'X';
        }
    } catch (error) {
        return 'X';
    }
}

const checkEmptyJson = data => {
     if(JSON.stringify(data) === '{}'){
        return false
     }
    return true
}

const limitText = (text, limit = 40) => {
    if (text.length > limit) {
      return text.substring(0, limit - 3).trim() + "...";
    }
    return text;
}

const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {
    if(mobileNumber !== '' && mobileNumber.length < 10){
        return false;
    }
    else if (mobileNumber !== '' && mobileNumber.length > 11){
        return false
    }else if(mobileNumber.length === 11 && mobileNumber.substring(0,1) !== 1){
        return false;
    }
    return true    
}

const checkLetterExistInText = (text) => {
    var regExp = /[a-zA-Z]/g;
    let result = false
    if(regExp.test(text)){
        result = true
    }
    return result
}

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 */
export const getAccountData = (needle) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }

    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    showNotification,
    checkEmptyJson,
    limitText,
    validateEmail,
    validateMobileNumber,
    checkLetterExistInText,
    formatPhoneNumber,
    getAccountData
}

export default Utils;