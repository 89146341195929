import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFacebookAppSubscribes, setFacebookSubscribeLoading } from "../../actions/facebookAction";
import { subscribeApp } from "../../api/facebookApi";
import Utils from "../../helpers/Utils";

const useStyles = makeStyles({
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      }
    },
    inputRoot : {
     padding : "0px !important"
    },
    inputFocused : {
        marginBottom : "0px !important"
    }
});

const FacebookPageSubscribe = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const pageData = useSelector((state) => state.facebookReducer.pageData );

    const [selectedPageId, setSelectedPageId] = useState(null);

    const subscribePage = () => {
        if(selectedPageId) {
            dispatch(setFacebookSubscribeLoading({subscribeLoading : true}));
            subscribeApp({page : selectedPageId}).then(res => {
                if(res.data.success) {
                    Utils.showNotification('Page subscribed successfully','SUCCESS');
                } else {
                    Utils.showNotification(res.data.message,'ERROR');
                }
                dispatch(fetchFacebookAppSubscribes());
            });
        }
    }

    const onChangeSubscribePage = (value) => {
        setSelectedPageId(value.id);
    }

    if(!pageData) return '';

    return ( 
        <div className="row">
            <div className="custom_grid_5">
                <div className="content_card">
                     <div className="facebook_page_subscriber">
                            <h6>You can connect multiple facebook page</h6>
                            <h4>Select your page</h4>
                        </div>   
                    <div className="card_body justify-content-start">
                        <Autocomplete
                            className="facebook_select"
                            disableClearable
                            onChange={(e,value) => onChangeSubscribePage(value)}
                            options={pageData}
                            classes={classes}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 500 }}
                            renderInput={(params) => <TextField {...params} placeholder="Select a page" variant="outlined" />}
                            />
                        <button className="custom_btn subscribe__facebook_button" onClick={() => subscribePage()}>Subscribe Page</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default FacebookPageSubscribe;