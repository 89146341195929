import { Card, CardContent, Typography } from "@material-ui/core";
import { Facebook } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useFacebookConnectStyle } from "./styles";

const FacebookConnect = () => {

    const classes = useFacebookConnectStyle();
    const loginUrl = useSelector((state) => state.facebookReducer.loginUrl);

    return ( 
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title}  gutterBottom>
                    Setup
                </Typography>
                <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
                    Basic setup for ad launch in facebook
                </Typography>
                <hr/>
                <div className={classes.body}>
                    <a className={classes.link} href={loginUrl}> <Facebook/>&nbsp;Connect to Facebook</a>
                </div>
            </CardContent>
        </Card>
     );
}
 
export default FacebookConnect;