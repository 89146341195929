import './token.css';
import React, { useEffect } from "react";
import {getAccessToken} from "../../api/calendlyApi";
import Loading from "../Common/Loading";
import Utils from "../../helpers/Utils";
import { useHistory } from "react-router-dom";

const Token = props => {
    const history = useHistory();

    useEffect(() => {
        document.title = `Calendly Token | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('integration');

        const queryParams = new URLSearchParams(window.location.search);
        getAccessToken({code: queryParams.get('code')}).then(response => {
            if (response.data.status) {
                Utils.showNotification(response.data.message, 'SUCCESS');
            } else {
                Utils.showNotification(response.data.message, 'ERROR');
            }

            history.push("/integrations/calendly");
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container">
            <div className="loader">
                <Loading size={50}/>
            </div>
        </div>
    );
}

export default Token;