import axios from "axios";
import { getCookie } from "../helpers/Cookie";


export const sendTenantRequest = (method, url, postData) => {

    return fetch(url, {
      method: method,
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getCookie(process.env.REACT_APP_ACCESS_TOKEN),
          // 'Access-Control-Allow-Headers': '*',
  
          // Authorization: 'Bearer W6GSG6PQ6K5ZCQ1UQT5E' //stage api
  
      },
      // referrer: 'origin-when-cross-origin',
      referrerPolicy: 'origin',
      // mode: 'cors',
      body: JSON.stringify(postData)
  })
  .then((response) => response.json())
  .then((responseData) => {
    return responseData
  })
  .catch(error=>{
    return false;
  })
}
