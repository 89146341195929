const HelpPanel = (props) => {

    if(props.name === 'WEALTHBOX') {
        return (
            <div className="page_content pt-1">
                <div className="content_footer">
                    <h5>Why do I need my Wealthbox API Key and where can I find it?</h5>
                    <p className="sm_font">You will need your Wealthbox key to establish a connection between Wealthbox and CRM.</p>
                    <div className="footer_action">
                        <p>Login to Wealthbox CRM, then goto to <strong>Settings {`>`} API Access</strong> or <a href="https://app.crmworkspace.com/settings/access_tokens">Goto this link</a></p>
                        <p>Click <strong>Create Access Token</strong> , put a username, password, APIKey and save.</p>
                    </div>
                    {/* <p>Need help? <a href="#">Click here</a> for a quick tutorial.</p> */}
                </div>
            </div>
        )
    }

    return '';
}
 
export default HelpPanel;