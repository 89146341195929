import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Integration from "../components/Integrations";
import Calendly from "../components/Calendly";
import CalendlyToken from "../components/Calendly/token";
import FacebookIndex from '../components/Facebook/FacebookIndex';
import IntegrationApp from '../components/Integrations/IntegrationApp';
import FacebookCallback from '../components/Facebook/Callback/FacebookCallback';
import HealthCheck from '../components/HealthCheck';
import InvoicePayment from '../components/Invoice/InvoicePayment';

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/user/calendly/token/get" component={CalendlyToken}/>
                    <Route exact path="/integrations/calendly" component={Calendly}/>

                    <Route exact path="/integrations/facebook" component={FacebookIndex}/>
                    <Route exact path="/user/facebook/connection/callback" component={FacebookCallback}/>

                    <Route exact path="/integrations" component={Integration}/>
                    <Route exact path="/integrations/app/:appId" component={IntegrationApp}/>

                    <Route exact path="/invoice/payment/:agencyId/:invoiceId" component={InvoicePayment}/>

                    <Route path="/app-store/health-check" component={HealthCheck}/>


                </Switch>
            </Router>
        );
    }
}

export default Routes;