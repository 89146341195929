import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getFacebookInfo = async (payload) => {
    return sendRequest("POST", Api.getFacebookInfo, payload);
}

export const getSubscribedPages = async (payload) => {
    return sendRequest("POST", Api.getSubscribedPages, payload);
}

export const subscribeApp = async (payload) => {
    return sendRequest("POST", Api.subscribeApp, payload);
}

export const unsubscribeApp = async (payload) => {
    return sendRequest("POST", Api.unsubscribeApp, payload);
}

export const assignCampaignToForm = async (payload) => {
    return sendRequest("POST", Api.assignCampaignToForm, payload);
}

export const assignLeadflowToForm = async (payload) => {
    return sendRequest("POST", Api.assignLeadflowToForm, payload);
}

export const changeStatusOfFbForm = async (payload) => {
    return sendRequest("POST", Api.changeStatusOfFbForm, payload);
}

export const getMapFields = async (payload) => {
    return sendRequest("POST", Api.getMapFields, payload);
}

export const storeMapFields = async (payload) => {
    return sendRequest("POST", Api.storeMapFields, payload);
}

export const deAuthorize = async (payload) => {
    return sendRequest("POST", Api.deAuthorize, payload);
}

export const connectUserFacebookAccount = async (payload) => {
    return sendRequest("POST", Api.connectUserFacebookAccount, payload);
}