import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getCalendlyInfo = async (payload) => {
    return sendRequest("POST", Api.getCalendlyInfo, payload);
}

export const disconnectCalendlyIntegration = async (payload) => {
    return sendRequest("POST", Api.disconnectCalendly, payload);
}

export const getAccessToken = async (payload) => {
    return sendRequest("POST", Api.getAccessToken, payload);
}