import { Close } from "@material-ui/icons";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../helpers/Utils";
import { fetchFacebookAppSubscribes, setFacebookSubscribeLoading } from "../../actions/facebookAction";
import BootstrapTooltip from "../Common/BootstrapTooltip";
import { unsubscribeApp } from "../../api/facebookApi";

const FacebookSubscribedList = () => {

    const dispatch = useDispatch();
    const subscribedPages = useSelector((state) => state.facebookReducer.subscribedPages);


    const handleUnsubscribe = (e,pageId) => {
        e.preventDefault();
        confirmAlert({
            title: 'Do you want to remove this connection?',
            message: 'You won\'t receive leads through this connection!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(setFacebookSubscribeLoading({subscribeLoading : true}));
                        unsubscribeApp({pageId : pageId}).then(res => {
                            if(res.data.success) {
                                Utils.showNotification(res.data.message,'SUCCESS');
                            } else {
                                Utils.showNotification(res.data.message,'ERROR');
                            }
                            dispatch(fetchFacebookAppSubscribes());
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const renderSubscribedPages = () => {
        return subscribedPages.map((page,index) => {
            return <div className="f_page_details d-flex" key={index}>
                <img src={page.image} alt={page.name}/>
                <div className="f_page_name">
                    <h6>{page.name}</h6>  
                </div>
                <BootstrapTooltip arrow title="Unsubscribe?" placement="top">
                    <div className="cross_icon" onClick={(e) => handleUnsubscribe(e,page.id)}>
                        <Close/>
                    </div>
                </BootstrapTooltip>
            </div>
        })
    }

    return ( 
        <div className="row">
            <div className="custom_grid_5">
                <div className="content_card facebookpage_card_content">
                     <div className="f_page_card d-flex">
                        {
                            renderSubscribedPages()
                        }
                     </div>
                </div>
            </div>
        </div>
     );
}
 
export default FacebookSubscribedList;