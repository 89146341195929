import { Settings } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllIntegrations } from "../../api/integrationApi";

const IntegrationAppList = () => {

    const [apps,setApps] = useState([]);
    
    useEffect(() => {
        getAllIntegrations({}).then(res => {
            setApps(res.data.data);
        });
    },[])

    return apps.map((app,index) => {
        return <div key={index} className="col l4 s6">
            <div className="integration_card">
                <div className="card_body">
                    <img src={app.logo} alt={app.name} />
                    <h6>{app.name}</h6>
                    <p>{app.description}</p>
                    <Link to={'/integrations/app/'+app.id} className="custom_btn accent--bg--color"><Settings/> <span>Setting</span></Link>
                </div>
            </div>
        </div>
    });
}
 
export default IntegrationAppList;