import { FormControl } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const MapFieldsLoader = () => {
    return (
        <> 
        <div>
            <h3 className="map_field__modal_title mt10pxSpace"><Skeleton variant="rectangular"  width={100}/></h3>
            <FormControl className="alt customFormControlFb">
                <Skeleton variant="rectangular" width={500}/>
            </FormControl>
        </div>
        <div>
            <h3 className="map_field__modal_title mt10pxSpace"><Skeleton variant="rectangular"  width={100}/></h3>
            <FormControl className="alt customFormControlFb">
                <Skeleton variant="rectangular" width={500}/>
            </FormControl>
        </div>
        <div>
            <h3 className="map_field__modal_title mt10pxSpace"><Skeleton variant="rectangular"  width={100}/></h3>
            <FormControl className="alt customFormControlFb">
                <Skeleton variant="rectangular" width={500}/>
            </FormControl>
        </div>
        <div>
            <h3 className="map_field__modal_title mt10pxSpace"><Skeleton variant="rectangular"  width={100}/></h3>
            <FormControl className="alt customFormControlFb">
                <Skeleton variant="rectangular" width={500}/>
            </FormControl>
        </div>
        </>
     );
}
 
export default MapFieldsLoader;