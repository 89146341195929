import { Close } from "@material-ui/icons";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetFacebookUserProfile } from "../../actions/facebookAction";
import { deAuthorize } from "../../api/facebookApi";
import Utils from "../../helpers/Utils";

const FacebookHeader = () => {

    const dispatch = useDispatch();
    const facebookUser = useSelector((state) => state.facebookReducer.facebookUser);
    const isLoading = useSelector((state) => state.facebookReducer.isLoading);
    const fbImage = useSelector((state) => state.facebookReducer.fbImage);
    const fbName = useSelector((state) => state.facebookReducer.fbName);


    const handleUnsubscribeUser = () => {
        confirmAlert({
            title: 'Do you want to disconnect your profile?',
            message: 'You will lose all your connections!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deAuthorize().then(res => {
                            if(res.data.success) {
                                Utils.showNotification(res.data.message,'SUCCESS');
                                dispatch(resetFacebookUserProfile());
                            } else {
                                Utils.showNotification(res.data.message,'ERROR');
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    return ( 
        <div className="page_heading">
            <div className="header_action">
                {/* {
                    !isLoading && facebookUser !== null && 
                    <div className="form_group search">
                        <input type="text" placeholder="Search page or form name"/>
                        <button type="submit">
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1445 11H12.3545L12.0745 10.73C13.0545 9.59 13.6445 8.11 13.6445 6.5C13.6445 2.91 10.7345 0 7.14453 0C3.55453 0 0.644531 2.91 0.644531 6.5C0.644531 10.09 3.55453 13 7.14453 13C8.75453 13 10.2345 12.41 11.3745 11.43L11.6445 11.71V12.5L16.6445 17.49L18.1345 16L13.1445 11ZM7.14453 11C4.65453 11 2.64453 8.99 2.64453 6.5C2.64453 4.01 4.65453 2 7.14453 2C9.63453 2 11.6445 4.01 11.6445 6.5C11.6445 8.99 9.63453 11 7.14453 11Z" fill="#133159" fillOpacity="0.65"/>
                            </svg>									
                        </button>
                    </div>
                } */}
                
                <Link to="/integrations" className="accent--bg--color">
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5815 0.706524C6.82489 0.706048 7.06076 0.790813 7.24817 0.946107C7.35365 1.03355 7.44083 1.14095 7.50474 1.26215C7.56865 1.38334 7.60802 1.51595 7.6206 1.65239C7.63318 1.78882 7.61872 1.9264 7.57805 2.05723C7.53738 2.18807 7.4713 2.3096 7.38358 2.41486L2.71692 7.99819L7.21692 13.5919C7.30344 13.6985 7.36806 13.8211 7.40705 13.9527C7.44604 14.0843 7.45864 14.2223 7.44412 14.3588C7.4296 14.4953 7.38825 14.6276 7.32244 14.748C7.25664 14.8685 7.16767 14.9747 7.06067 15.0607C6.95289 15.1555 6.82667 15.227 6.68994 15.2708C6.55321 15.3145 6.40891 15.3295 6.26611 15.3149C6.1233 15.3002 5.98507 15.2562 5.86009 15.1855C5.73511 15.1149 5.62608 15.0192 5.53983 14.9044L0.508583 8.65444C0.355373 8.46805 0.271617 8.23426 0.271617 7.99298C0.271617 7.75171 0.355373 7.51791 0.508583 7.33152L5.71692 1.08152C5.82141 0.955464 5.95416 0.855815 6.10438 0.79066C6.2546 0.725505 6.41806 0.696679 6.5815 0.706524Z" fill="white"/>
                    </svg>	
                    Back
                </Link>
            </div>

            <div className="title">
            {
                !isLoading && facebookUser !== null &&
                <>
                    <h4>Connected as Profile</h4>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                        <img src={fbImage} alt=""/>
                            {fbName}	
                    </a>
                    <Close className="cursor-pointer" onClick={handleUnsubscribeUser}/>
                </>
            }
            </div>
        </div>
     );
}
 
export default FacebookHeader;