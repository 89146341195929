import { MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { assignCampaignToForm, assignLeadflowToForm, changeStatusOfFbForm } from "../../api/facebookApi";
import { LEADFLOW_STATUS } from "../../constants/CoreConstants";
import Utils from "../../helpers/Utils";
import { BootstrapInput } from "../Common/BootstrapInput";
import FacebookMapFieldModal from "./FacebookMapFieldModal";
import FacebookPageSubscribe from "./FacebookPageSubscribe";
import FacebookSubscribedList from "./FacebookSubscribedList";
import SubscribeListLoader from "./SubscribeListLoader";

const FacebookSettingBody = () => {

    const subscribeLoading = useSelector((state) => state.facebookReducer.subscribeLoading);
    const allFbForms = useSelector((state) => state.facebookReducer.allFbForms);

    return ( 
        <>
            <FacebookPageSubscribe/>

            {
                subscribeLoading ? <SubscribeListLoader/> :
                <>
                    <FacebookSubscribedList/>
                    {
                        allFbForms.map((elem,index) => {
                            return <EachFBPageSetting page={elem} key={index}/> 
                        })
                    }
                </>
            }
        </>
     );
}

const EachFBPageSetting = (props) => {

    const allCampaigns = useSelector((state) => state.facebookReducer.allCampaigns);
    const allLeadFlow = useSelector((state) => state.facebookReducer.allLeadFlow);
    const fbName = useSelector((state) => state.facebookReducer.fbName);
    const [status,setStatus] = useState(LEADFLOW_STATUS.STATUS_PAUSE);
    const [openMapModal,setOpenMapModal] = useState(false);

    useEffect(() => {
        if(props.page.page_lead_flow !== undefined) {
            setStatus(props.page.page_lead_flow.status);
        }
    },[props.page.page_lead_flow])

    const renderCampaigns = () => {
        let campaignArr = [];
        if(allCampaigns) {
            for(const campaignId in allCampaigns) {
                campaignArr.push(<MenuItem key={campaignId} value={campaignId}>{allCampaigns[campaignId]}</MenuItem>)
            }
        }
        return campaignArr;
    }

    const renderLeadFlows = () => {
        let leadFlowsArr = [];
        if(allLeadFlow) {
            for(const leadFlowId in allLeadFlow) {
                leadFlowsArr.push(<MenuItem key={leadFlowId} value={leadFlowId}>{allLeadFlow[leadFlowId]}</MenuItem>)
            }
        }
        return leadFlowsArr;
    }

    const handleAssignCampaignToForm = (e) => {
        assignCampaignToForm({
            formId : props.page.form.id,
            campaignId : e.target.value,
            fbPageId : props.page.page_lead_flow.user_page_id,
            formName : props.page.form.name
        }).then(res => {
            if(res.data.success) {
                if(e.target.value === null) {
                    Utils.showNotification('Campaign unassigned','SUCCESS');
                } else {
                    Utils.showNotification(res.data.message,'SUCCESS');
                }
            } else {
                Utils.showNotification(res.data.message,'ERROR');
            }
        });
    }

    const handleAssignLeadflowToForm = (e) => {
        assignLeadflowToForm({
            formId : props.page.form.id,
            leadflowId : e.target.value,
            fbPageId : props.page.page_lead_flow.user_page_id,
            formName : props.page.form.name
        }).then(res => {
            if(res.data.success) {
                if(e.target.value === null) {
                    Utils.showNotification('Leadflow unassigned','SUCCESS');
                } else {
                    Utils.showNotification(res.data.message,'SUCCESS');
                }
            } else {
                Utils.showNotification(res.data.message,'ERROR');
            }
        });
    }

    const handleChangeFormStatus = (e) => {
        let oldStatus = status;
        let newStatus = status === LEADFLOW_STATUS.STATUS_ACTIVE ? LEADFLOW_STATUS.STATUS_PAUSE : LEADFLOW_STATUS.STATUS_ACTIVE;
        setStatus(newStatus);
        changeStatusOfFbForm({
            status : newStatus,
            formId : props.page.form.id,
            fbPageId : props.page.page_lead_flow.user_page_id,
            formName : props.page.form.name
        }).then(res => {
            if(res.data.success) {
                Utils.showNotification(res.data.message,'SUCCESS');
            } else {
                setStatus(oldStatus);
                Utils.showNotification(res.data.message,'ERROR');
            }
        });
    }

    return (
        <div className="row">
            <div className="custom_grid_5">
                <div className="content_card">
                    <div className="card_header">
                        <div className="title">
                            <a href={`https://www.facebook.com/${props.page.id}`} target="_blank" rel="noreferrer"><img src={props.page.image} alt={props.page.image}/>{props.page.name}</a>
                        </div>
                    </div>
                    {
                        props.page.form === null ? 
                        <p>There are no form connected to this page</p> :
                        <div className="card_body">
                            <a href={`https://www.facebook.com/adsmanager/manage/`} target="_blank" rel="noreferrer">{props.page.form.name}</a>
                            <p>{status === LEADFLOW_STATUS.STATUS_PAUSE ? 'PAUSE' : 'ACTIVE'}</p>
                            <button className="custom_btn" onClick={() => setOpenMapModal(true)}>Map Fields</button>
                            <label className="switch">
                                <small className="on">	
                                    <svg width="57" height="32" viewBox="0 0 57 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 16C1 7.99187 7.49187 1.5 15.5 1.5H41.5C49.5081 1.5 56 7.99187 56 16C56 24.0081 49.5081 30.5 41.5 30.5H15.5C7.49187 30.5 1 24.0081 1 16Z" fill="#F6F7FB"/>
                                        <rect x="29" y="3.5" width="25" height="25" rx="12.5" fill="#3C7EF3"/>
                                        <path d="M38.2656 16.4987L41.4656 19.1654L45.7323 13.832" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M15.5 2.5H41.5V0.5H15.5V2.5ZM41.5 29.5H15.5V31.5H41.5V29.5ZM15.5 29.5C8.04416 29.5 2 23.4558 2 16H0C0 24.5604 6.93959 31.5 15.5 31.5V29.5ZM55 16C55 23.4558 48.9558 29.5 41.5 29.5V31.5C50.0604 31.5 57 24.5604 57 16H55ZM41.5 2.5C48.9558 2.5 55 8.54416 55 16H57C57 7.43959 50.0604 0.5 41.5 0.5V2.5ZM15.5 0.5C6.93959 0.5 0 7.43959 0 16H2C2 8.54416 8.04416 2.5 15.5 2.5V0.5Z" fill="#133159" fillOpacity="0.1"/>
                                    </svg>	
                                </small>
                                <input type="checkbox" checked={status === LEADFLOW_STATUS.STATUS_ACTIVE} onChange={(e) => handleChangeFormStatus()}/>
                                <span className="slider round"></span>
                                <small className="off">
                                    <svg width="57" height="32" viewBox="0 0 57 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 16C1 7.99187 7.49187 1.5 15.5 1.5H41.5C49.5081 1.5 56 7.99187 56 16C56 24.0081 49.5081 30.5 41.5 30.5H15.5C7.49187 30.5 1 24.0081 1 16Z" fill="#F6F7FB"/>
                                        <rect x="4" y="3.5" width="25" height="25" rx="12.5" fill="#FF264A"/>
                                        <path d="M12.5413 19.5832L16.3125 15.812M16.3125 15.812L20.0837 12.0408M16.3125 15.812L20.0837 19.5832M16.3125 15.812L12.5413 12.0408" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M15.5 2.5H41.5V0.5H15.5V2.5ZM41.5 29.5H15.5V31.5H41.5V29.5ZM15.5 29.5C8.04416 29.5 2 23.4558 2 16H0C0 24.5604 6.93959 31.5 15.5 31.5V29.5ZM55 16C55 23.4558 48.9558 29.5 41.5 29.5V31.5C50.0604 31.5 57 24.5604 57 16H55ZM41.5 2.5C48.9558 2.5 55 8.54416 55 16H57C57 7.43959 50.0604 0.5 41.5 0.5V2.5ZM15.5 0.5C6.93959 0.5 0 7.43959 0 16H2C2 8.54416 8.04416 2.5 15.5 2.5V0.5Z" fill="#133159" fillOpacity="0.1"/>
                                    </svg>	
                                </small>
                            </label>
                        </div>
                    }

                </div>
            </div>
            {
                props.page.form !== null &&
                <>
                    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.664062 11.8302V15.1635H20.6641L11.4974 24.3302L13.8641 26.6969L27.0641 13.4969L13.8641 0.296875L11.4974 2.66354L20.6641 11.8302H0.664062Z" fill="#133159" fillOpacity="0.35"/>
                    </svg>	
                    <div className="custom_grid_6">
                        <div className="content_card">
                            <div className="card_header">
                                <h5>Connected as <span>{fbName}</span></h5>
                            </div>
                            <div className="card_body">
                                <div className="input_group">
                                    <label>Connected Campaign </label>
                                    <div className="option_select">
                                        <Select
                                            displayEmpty
                                            className="input-field"
                                            defaultValue={props.page.page_lead_flow.campaign_id}
                                            onChange={handleAssignCampaignToForm}
                                            input={<BootstrapInput />}>
                                            <MenuItem value={null}>Select a campaign</MenuItem>
                                            {renderCampaigns()}								
                                        </Select>
                                    </div>
                                </div>
                                <div className="input_group">
                                    <label>Connected Leadflow</label>
                                    <div className="option_select sm">
                                        <Select 
                                            displayEmpty
                                            className="input-field" 
                                            defaultValue={props.page.page_lead_flow.leadflow_id}
                                            onChange={handleAssignLeadflowToForm}
                                            input={<BootstrapInput />}>
                                            <MenuItem value={null}>Select a leadflow</MenuItem>
                                            {renderLeadFlows()}								
                                        </Select>
                                    </div>
                                </div>
                                <div className="all_result">
                                    <span>All Contact</span>
                                    <h4>{props.page.page_lead_flow.total_contact}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {
                openMapModal && 
                <FacebookMapFieldModal 
                    open={openMapModal} 
                    onClose={(e) => setOpenMapModal(false)}
                    formId={props.page.form.id}
                    pageId={props.page.page_lead_flow.user_page_id}
                />
            }

            
        </div>
    );
}
 
export default FacebookSettingBody;