import React from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';

const defaultHistory = createBrowserHistory();

function App({ history = defaultHistory }) {
    return (
        <Router history={history}>
            <React.Fragment>
                <Routes />
            </React.Fragment>
        </Router>
    );
}

export default App;
