import './integration.css';
import {Link} from 'react-router-dom'
import Utils from "../../helpers/Utils";
import {useEffect} from "react";
import IntegrationAppList from './IntegrationAppList';
import { Settings } from '@material-ui/icons';

const Integrations = () => {
    useEffect(() => {
        document.title = `Integrations | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('integration');
    }, []);

    return (
        <div className="integration_section">
            <div className="page_heading">
                <h4>Integration</h4>
            </div>
            <h4 className="page_title">Applications</h4>
            <div className="page_content">
                <div className="row">
                    <div className="col l4 s6">
                        <div className="integration_card">
                            <div className="card_body">
                                <img src={`${process.env.REACT_APP_APP_URL}/assets/images/integration/img_3.png`} alt="FACEBOOK" />
                                <h6>FACEBOOK</h6>
                                <p>{Utils.getAccountData('AuthUserAgencyName')} will receive your name and profile picture.</p>
                                <Link to='/integrations/facebook' className="custom_btn accent--bg--color"><Settings/> <span>Setting</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col l4 s6">
                        <div className="integration_card">
                            <div className="card_body">
                                <img src={`${process.env.REACT_APP_APP_URL}/assets/images/integration/img_4.png`} alt="Calendly" />
                                <h6>Calendly</h6>
                                <p>You're not setting up any Calendly with system.</p>
                                <Link to='/integrations/calendly' className="custom_btn accent--bg--color"><Settings/> <span>Setting</span></Link>
                            </div>
                        </div>
                    </div>

                    <IntegrationAppList/>

                </div>
            </div>
        </div>
    );
}

export default Integrations;