import { Card, CardContent, Typography } from "@material-ui/core";
import { useFacebookSetupRequiredStyle } from "./styles";

const FacebookSetupRequired = () => {

    const classes = useFacebookSetupRequiredStyle();
    const bull = <span className={classes.bullet}>•</span>;

    return ( 
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title}  gutterBottom>
                    Setup
                </Typography>
                <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
                    Basic setup for ad launch in facebook
                </Typography>
                <hr/>
                <div className={classes.body}>
                    <Typography className={classes.subtitle} gutterBottom>
                        {bull} Your agency yet not requested or has not active facebook lead fetch service
                    </Typography>
                    <Typography className={classes.subtitle} gutterBottom>
                        {bull} Contact with your agency to get the facebook lead fetch service to fetch fb lead form lead to this system
                    </Typography>
                </div>
            </CardContent>
        </Card>
     );
}
 
export default FacebookSetupRequired;