import Api from "../constants/Api";
import { sendTenantRequest } from './rootTenantApi';


export const createPaymentIntent = async (payload) => {
    return sendTenantRequest("POST", Api.createPaymentIntent, payload);
}

export const takeStripePayment = async (payload) => {
    return sendTenantRequest("POST", Api.takeStripePayment, payload);
}