import { all } from "redux-saga/effects";
import calendlyMiddleware from "./calendlyMiddleware";
import facebookMiddleware from "./facebookMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            calendlyMiddleware(),
            facebookMiddleware()
        ]
    )
}
