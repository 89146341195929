import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as FACEBOOK_API from '../api/facebookApi';
import * as ACTION from '../constants/facebookActionTypes'
import Utils from "../helpers/Utils";

function* facebookWatcher() {
    yield takeEvery(ACTION.FETCH_FACEBOOK, fetchFacebookMiddleware);
    yield takeEvery(ACTION.FETCH_FACEBOOK_SUBSCRIBES, fetchFacebookSubscribeMiddleware);
}

function* fetchFacebookMiddleware(action) {
    try {
        yield put({type: ACTION.SET_FACEBOOK_LOADING, payload: {isLoading:true}});
        const response = yield call(FACEBOOK_API.getFacebookInfo, action.payload);
        let responseData = response.data.data;
        yield put({type: ACTION.STORE_FACEBOOK, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchFacebookSubscribeMiddleware(action) {
    try {
        yield put({type: ACTION.FETCH_FACEBOOK_SUBSCRIBES_LOADING, payload: {subscribeLoading:true}});
        const response = yield call(FACEBOOK_API.getSubscribedPages, action);
        let responseData = response.data.data;
        yield put({type: ACTION.STORE_SUBSCRIBED_PAGES, payload: responseData});
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* facebookMiddleware() {
    yield all([
        facebookWatcher(),
    ])
}
