import { all } from 'redux-saga/effects';
// import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
// import * as CALENDAR_API from '../api/calendlyApi';
// import * as ACTION from '../constants/calendlyActionTypes'
// import Utils from "../helpers/Utils";

function* calendlyWatcher() {
    // yield takeEvery(ACTION.FETCH_CALENDLY, fetchCalendarMiddleware);
}

// function* fetchCalendarMiddleware(action) {
//     try {
//         const response = yield call(CALENDAR_API.fetchCalendlyList, action.payload);
//         let responseData = response.data;
//         yield put({type: ACTION.STORE_CALENDLY, payload: responseData});
//
//     } catch (error) {
//         Utils.handleException(error);
//     }
// }

export default function* calendlyMiddleware() {
    yield all([
        calendlyWatcher(),
    ])
}
