import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFacebookApp, setFacebookLoading } from '../../actions/facebookAction';
import Loading from '../Common/Loading';
import FacebookConnect from './FacebookConnect';
import FacebookHeader from './FacebookHeader';
import FacebookSettingBody from './FacebookSettingBody';
import FacebookSetupRequired from './FacebookSetupRequired';
import './facebook.css';
import Utils from "../../helpers/Utils";

const FacebookIndex = () => {

    const dispatch = useDispatch();
    const facebookApp = useSelector((state) => state.facebookReducer.facebookApp);
    const facebookUser = useSelector((state) => state.facebookReducer.facebookUser);
    const isLoading = useSelector((state) => state.facebookReducer.isLoading);

    useEffect(() => {
        document.title = `Facebook App | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('integration');
        }

        dispatch(fetchFacebookApp());

        return () => {
            dispatch(setFacebookLoading({ isLoading: true }));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="facebook_section">
            <FacebookHeader />
            <div className="page_content">
                {
                    isLoading ? <Loading /> :
                        facebookApp === null ? <FacebookSetupRequired /> :
                            facebookUser === null ? <FacebookConnect /> : <FacebookSettingBody />
                }
            </div>
        </div>
    );
}

export default FacebookIndex;