import * as ACTION from "../constants/facebookActionTypes";
export const fetchFacebookApp = (payload, callback) => ({
    type: ACTION.FETCH_FACEBOOK,
    payload: {payload, callback}
});

export const fetchFacebookAppSubscribes = (payload) => ({
    type: ACTION.FETCH_FACEBOOK_SUBSCRIBES,
    payload: payload
});

export const setFacebookLoading = (payload) => ({
    type: ACTION.SET_FACEBOOK_LOADING,
    payload: payload
});

export const setFacebookSubscribeLoading = (payload) => ({
    type: ACTION.FETCH_FACEBOOK_SUBSCRIBES_LOADING,
    payload: payload
});

export const resetFacebookUserProfile = (payload) => ({
    type: ACTION.RESET_FACEBOOK_USER_DATA,
    payload: payload
});