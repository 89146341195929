import * as ACTION from "../constants/calendlyActionTypes";

const initialState = {
    calendars: null
};

const calendlyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_CALENDLY:
            return {...state, calendars: action.payload}

        default:
            return state;
    }
}

export default calendlyReducer;
