import './calendly_setup.css';
import {Link} from 'react-router-dom'
import {useEffect, useState} from "react";
import {getCalendlyInfo, disconnectCalendlyIntegration} from "../../api/calendlyApi";
import Loading from "../Common/Loading";
import Utils from "../../helpers/Utils";

const Calendly = () => {
    const [state, setState] = useState({
        loading: true,
        isCalendlyConnected: false,
        calendlyClientId: ''
    });

    useEffect(() => {
        document.title = `Calendly | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('integration');
    }, []);

    useEffect(() => {
        getCalendlyInfo().then(response => {
            if (response.data.status) {
                setState({
                    ...state,
                    loading: false,
                    isCalendlyConnected: response.data.data.isCalendlyConnected,
                    calendlyClientId: response.data.data.calendly_client_id,
                });
            }
        });
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const disconnectCalendly = () => {
        setState({...state, loading: true});

        disconnectCalendlyIntegration().then(response => {
            if (response.data.status) {
                setState({...state, isCalendlyConnected: false, loading: false})
                Utils.showNotification(response.data.message, 'SUCCESS');
            } else {
                setState({...state, loading: false})
                Utils.showNotification(response.data.message, 'ERROR');
            }
        });
    }

    return (
        <div className="wealthbox_section">
                <div className="page_heading">
                    <h4>Integration</h4>
                </div>
                <div className="page_title calendly_page_setup">
                    <h5>Calendly setup</h5>
                    <Link to="/integrations" className="accent--bg--color">
                        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.5815 0.706524C6.82489 0.706048 7.06076 0.790813 7.24817 0.946107C7.35365 1.03355 7.44083 1.14095 7.50474 1.26215C7.56865 1.38334 7.60802 1.51595 7.6206 1.65239C7.63318 1.78882 7.61872 1.9264 7.57805 2.05723C7.53738 2.18807 7.4713 2.3096 7.38358 2.41486L2.71692 7.99819L7.21692 13.5919C7.30344 13.6985 7.36806 13.8211 7.40705 13.9527C7.44604 14.0843 7.45864 14.2223 7.44412 14.3588C7.4296 14.4953 7.38825 14.6276 7.32244 14.748C7.25664 14.8685 7.16767 14.9747 7.06067 15.0607C6.95289 15.1555 6.82667 15.227 6.68994 15.2708C6.55321 15.3145 6.40891 15.3295 6.26611 15.3149C6.1233 15.3002 5.98507 15.2562 5.86009 15.1855C5.73511 15.1149 5.62608 15.0192 5.53983 14.9044L0.508583 8.65444C0.355373 8.46805 0.271617 8.23426 0.271617 7.99298C0.271617 7.75171 0.355373 7.51791 0.508583 7.33152L5.71692 1.08152C5.82141 0.955464 5.95416 0.855815 6.10438 0.79066C6.2546 0.725505 6.41806 0.696679 6.5815 0.706524Z"
                                fill="white"/>
                        </svg>
                        Back
                    </Link>
                </div>

                <div className="page_content">
                    { state.loading ?
                        <Loading size={50}/>
                    :
                        <div className="contnet_form">
                            { state.isCalendlyConnected ?
                                <>
                                    <h5>Calendly Connected</h5>
                                    <p>Setup Calendly with system completed successfully</p>
                                    <div className="btn_group">
                                        <button type="button" className="custom_btn save_btn accent--bg--color" onClick={() => disconnectCalendly()}>
                                            Disconnect Calendly
                                        </button>
                                    </div>
                                </>
                            :
                                <>
                                    <h5>Calendly Not Connected</h5>
                                    <p>You're not setting up any Calendly with system.<br />
                                        <span className="text-danger">If you already connected Calendly in this computer, on press "Connect" Button, it get connected with that account.</span>
                                    </p>
                                    { state.calendlyClientId ?
                                        <div className="btn_group">
                                            <a href={`https://auth.calendly.com/oauth/authorize?client_id=${state.calendlyClientId }&response_type=code&redirect_uri=https://${ window.location.hostname+'/user/calendly/token/get' }`} className="custom_btn save_btn accent--bg--color">
                                                Connect Calendly
                                            </a>
                                        </div>
                                        :
                                        <p>Calendly can't be integrated with your account. Please contact your agency</p>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
    );
}

export default Calendly;
