import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Redirect } from "react-router";
import { useParams } from 'react-router-dom';

// import "./StripePaymentForm.css";
import StripePaymentForm from "./StripePaymentForm";
import { createPaymentIntent } from "../../api/TenantApi";
import PaymentFailMessage from "./PaymentFailMessage";
import PaymentSuccessMessage from "./PaymentSuccessMessage";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

const InvoicePayment = (props) => {
  const [redirect, setRedirect] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [paymentCredentials, setPaymentCredentials] = useState(null);
  const [clientSecret, setClientSecret] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)
  const [amount, setAmount] = useState(null)

  const { invoiceId } = useParams();
  const { agencyId } = useParams();

  const stylePath = process.env.REACT_APP_CDN_LINK + "asset/css/releases/1.0.0/StripePaymentForm.css";

  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = stylePath;

    head.appendChild(link);

    return () => { head.removeChild(link); }

  }, [stylePath]);

  useEffect(() => {

    document.body.classList.add('invoice-class');

    return () => document.body.classList.remove('invoice-class')
  }, []);
  useEffect(() => {
    createPaymentIntent({ invoiceId: invoiceId, agencyId: agencyId }).then(response => {

      if (response && response.success && response.data && response.data.paymentMethod) {

        setPaymentCredentials(response.data)

        if (response.data.paymentMethod === 'STRIPE') {
          setClientSecret(response.data.clientSecret)
          setStripePromise(loadStripe(response.data.publishableKey))
          setAmount(response.data.amount)
        }
      } else {
        if (response) {
          if (response.message) {
            setErrorMessage(response.message)
          }
        }
        setRedirect('FAIL')
      }
    }).catch(error => {
      setRedirect('FAIL')
      // <Redirect to="/invoice/payment-request/fail" />
    });
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (redirect === 'FAIL') {
    return <PaymentFailMessage errorMessage={errorMessage} />
  } else if (redirect === 'SUCCESS') {
    return <PaymentSuccessMessage successMessage={successMessage} />
  } else {
    return (
      <div className="App">
        {clientSecret && stripePromise && (
          <div>
            <div >
              <h6 className="hit-the-floor">You have to pay: ${amount}</h6>
            </div>

            <Elements options={options} stripe={stripePromise}>
              <StripePaymentForm paymentCredentials={paymentCredentials} agencyId={agencyId} invoiceId={invoiceId} setRedirect={setRedirect} setSuccessMessage={setSuccessMessage} />
            </Elements>
          </div>
        )}
      </div>
    );
  }

}

export default InvoicePayment;