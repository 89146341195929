const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/app-store`
const tenantRootUrl = `${process.env.REACT_APP_TENANT_URL}/${process.env.REACT_APP_API_VERSION}`

const Api = {
    getCalendlyInfo: `${rootUrl}/calendly/info`,
    disconnectCalendly: `${rootUrl}/calendly/disconnect`,
    getAccessToken: `${rootUrl}/calendly/token/get`,

    getFacebookInfo: `${rootUrl}/facebook/info`,
    getSubscribedPages : `${rootUrl}/facebook/get-subscribes-pages`,
    subscribeApp : `${rootUrl}/facebook/subscribe-app`,
    unsubscribeApp : `${rootUrl}/facebook/unsubscribe-app`,
    assignCampaignToForm : `${rootUrl}/facebook/assign-campaign-to-form`,
    assignLeadflowToForm : `${rootUrl}/facebook/assign-leadflow-to-form`,
    changeStatusOfFbForm : `${rootUrl}/facebook/change-status-of-fb-form`,
    getMapFields : `${rootUrl}/facebook/get-map-fields`,
    storeMapFields : `${rootUrl}/facebook/store-map-fields`,
    deAuthorize : `${rootUrl}/facebook/user-deauthorize`,
    connectUserFacebookAccount : `${rootUrl}/facebook/connect-user-account`,

    getAllIntegrations : `${rootUrl}/integrations/get-all`,
    getAppDetails : `${rootUrl}/integrations/get-app-details`,
    insertIntegration : `${rootUrl}/integrations/insert-integration-form`,
    deleteIntegration : `${rootUrl}/integrations/delete-integration`,

    createPaymentIntent: `${tenantRootUrl}/invoice/create-payment-intent`,
    takeStripePayment: `${tenantRootUrl}/invoice/take-stripe-payment`,
}

export default Api;