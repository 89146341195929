import { Skeleton } from "@material-ui/lab";

const SubscribeListLoader = () => {
    return ( 
        <div class="row">
            <div class="custom_grid_5">
                <div class="content_card">
                    <div class="card_header">
                        <div class="title">
                            <Skeleton variant="rectangular" width={40} height={40}/>
                            <span className="pr-2"/>
                            <Skeleton variant="rectangular" width={150} height={20}/>
                        </div>
                        <a href="#!" onClick={e => e.preventDefault()}>
                            <Skeleton variant="rectangular" width={40} height={20}/>
                        </a>
                    </div>
                    <div class="card_body">
                        <Skeleton variant="rectangular" width={150} height={20}/>
                        <Skeleton variant="rectangular" width={150} height={20}/>
                        <Skeleton variant="rectangular" width={100} height={40}/>
                        <Skeleton variant="rectangular" width={40} height={20}/>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SubscribeListLoader;