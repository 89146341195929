import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { connectUserFacebookAccount } from "../../../api/facebookApi";
import Utils from "../../../helpers/Utils";
import Loading from "../../Common/Loading";
import './facebookCallback.css';

const FacebookCallback = () => {

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code =  queryParams.get('code');
        const state =  queryParams.get('state');

        connectUserFacebookAccount({code : code,state : state}).then(res => {
            Utils.showNotification(res.data.message,res.data.success ? 'SUCCESS' : 'ERROR');
            history.push('/integrations/facebook');
        })
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return ( 
        <div className="facebook_callback_wrapper">
            <Loading/>
            <h3 className="center">Activating your facebook app</h3>
        </div>
    );
}
 
export default FacebookCallback;