import { useState } from "react";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CircularProgress } from "@material-ui/core";

const IntegrationForm = (props) => {
    const [saving, setSaving] = useState(false);

    const formFields = () => {
        let fieldsArr = JSON.parse(props.form.fields);
        return fieldsArr.map((field,index) => <FormData key={index} field={field} data={props.data !== null ? props.data[field.name] : ""}/>)
    }

    const saveApi = (e) => {
        e.preventDefault();
        if(saving) {
            return;
        }

        setSaving(true);
        //Getting form data as json
        const formData = Array.from(e.target.elements)
            .filter(el => el.name)
            .reduce((a, b) => ({...a, [b.name]: b.value}), {});
        formData['form_id'] = props.form.id;
        props.saveApi(formData,() => {
            setSaving(false);
        });
    }

    return (
        <div className="page_content">
            <div className="d-flex align-items-center">
                <div className="app-store-title">
                    <h5>{props.form.title}</h5>
                    <p className="sm_font">{props.form.description}</p>
                </div>
                <h5 className="pl-5 ml-5">
                    Status : {props.data !== null ? <span className="green-text">Active</span> : <span className="red-text">Inactive</span>}
                </h5>
            </div>
            
            <form onSubmit={(e) => saveApi(e)}>
                {formFields()}

                <div className="btn_group">
                    <button type="submit" className="custom_btn eidt_btn">
                        {
                            saving ?
                            <>
                                <CircularProgress style={{width : 25, height : 25}}/>
                            </> : 
                            <>
                                <svg height="20" viewBox="0 0 512.007 512.007" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="#3C7EF3" d="M511.927 126.537a15.028 15.028 0 0 0-3.315-8.027c-.747-.913 6.893 6.786-114.006-114.113A15.116 15.116 0 0 0 383.994.003H44.999c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zM345 30.003v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113H142c-8.271 0-15-6.729-15-15v-98zm64 291H127v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92v-180c0-24.813-20.187-45-45-45H142c-24.813 0-45 20.187-45 45v180H45c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787L482 134.217z"/></svg>
                                {props.form.button_text == null ? "Save" : props.form.button_text }
                            </>
                        }
                    </button>
                </div>
            </form>
        </div>
    );
}
 
export default IntegrationForm;

const FormData = props => {

    const [showPassword,  setShowPassword] = useState(props.field.type !== 'password');

    return (
        <div className="input_group">
            <label>{props.field.label}</label>
            <div className="app_store_module__preview_field">
                <input name={props.field.name} placeholder={props.field.placeholder} defaultValue={props.data} className="validate" required="required" type={showPassword ? 'text' : 'password'}/>
                {
                    props.field.type === 'password' && 
                    <span class="password_visible" onClick={() => setShowPassword(!showPassword)}>
                        {
                            showPassword ? <VisibilityIcon/> : <VisibilityOff/>
                        }
                    </span>
                }
            </div>
        </div>
    )
}
